:root {
  --hot-pink: #ef0f97;
  --white: #ffffff;
  --orange: #e54446;
  --blue: #1c8bb8;
}

* {
  margin: 0;
  padding: 0;
}

.homebackground {
  background-color: black;
  height: 100%;
}

.homeimage {
  transform-origin: 50% 50%;
  animation: 15s rotateRight infinite linear;
}

@keyframes rotateRight {
  100% {
    transform: rotate(360deg);
  }
}

.homelayout {
  text-align: center;
  padding-top: 5%;
}

.name {
  color: var(--hot-pink);
  font-family: "Streamster";
  font-size: 120px;
}

.title {
  color: var(--white);
  font-family: "Open Sans";
  padding: 0% 12%;
  font-size: 18px;
}

.aboutbackground {
  background-color: var(--orange);
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 1) 0%,
    rgba(229, 68, 70, 0.9556197478991597) 10%
  );
}

.aboutflex {
  display: flex;
}

.about_text_flex {
  color: white;
  padding: 3% 0% 2% 3%;
}

p {
  padding-top: 5%;
}

.headshot {
  height: auto;
  width: 60vw;
  padding-top: 7%;
  padding-left: 30px;
}

.hey {
  font-family: "La Belle Aurore", cursive;
  font-size: 100px;
}

a Link {
  text-decoration: none;
}

.btn-info {
  width: 60%;
}

.project_image {
  width: auto;
  height: auto;
  margin: 42px;
  box-shadow: -5px -5px 30px 5px purple, 5px 5px 30px 5px orange;
}

.hovereffect {
  width: 100%;
  height: 100%;
  float: left;
  overflow: hidden;
  position: relative;
  text-align: center;
  cursor: default;
}

.hovereffect .overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  opacity: 0;
  filter: alpha(opacity=0);
  -webkit-transform: translate(460px, -100px) rotate(180deg);
  -ms-transform: translate(460px, -100px) rotate(180deg);
  transform: translate(460px, -100px) rotate(180deg);
  -webkit-transition: all 0.2s 0.4s ease-in-out;
  transition: all 0.2s 0.4s ease-in-out;
}

.hovereffect img {
  display: block;
  position: relative;
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}

.hovereffect h2 {
  text-transform: uppercase;
  color: #fff;
  text-align: center;
  position: relative;
  font-size: 17px;
  padding: 10px;
  background: rgba(0, 0, 0, 0.6);
}

.hovereffect a.info {
  display: inline-block;
  text-decoration: none;
  padding: 7px 14px;
  text-transform: uppercase;
  color: #fff;
  border: 1px solid #fff;
  margin: 50px 0 0 0;
  background-color: transparent;
  -webkit-transform: translateY(-200px);
  -ms-transform: translateY(-200px);
  transform: translateY(-200px);
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.hovereffect a.info:hover {
  box-shadow: 0 0 5px #fff;
}

.hovereffect:hover .overlay {
  opacity: 1;
  filter: alpha(opacity=100);
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
  -webkit-transform: translate(0px, 0px);
  -ms-transform: translate(0px, 0px);
  transform: translate(0px, 0px);
}

.hovereffect:hover h2 {
  -webkit-transform: translateY(0px);
  -ms-transform: translateY(0px);
  transform: translateY(0px);
  -webkit-transition-delay: 0.5s;
  transition-delay: 0.5s;
}

.hovereffect:hover a.info {
  -webkit-transform: translateY(0px);
  -ms-transform: translateY(0px);
  transform: translateY(0px);
  -webkit-transition-delay: 0.3s;
  transition-delay: 0.3s;
}

.projects_title {
  text-align: center;
  padding-top: 20%;
  font-size: 120px;
  font-family: "Blackout";
}

.projects_hover {
  text-align: center;
  font-size: 18px;
}

.projectflex {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-left: 3%;
  padding-top: 5%;
}

.img-responsive {
  height: 310px;
  width: 550px;
}

.projects_background {
  background-color: var(--blue);
  background: linear-gradient(
    to bottom,
    rgba(229, 68, 70, 0.9556197478991597) 0%,
    var(--blue) 10%
  );
}

.contact_background {
  background-color: black;
  background: linear-gradient(to bottom, var(--blue) 0%, black 10%);
}

.contact_heading {
  font-size: 100px;
  text-align: center;
  padding-top: 12%;
  font-family: "Frontage Condensed Outline";
  color: var(--white);
}

.form_styling {
  width: 85%;
  padding: 2% 0% 5% 20%;
}

.fade-in-section {
  opacity: 0;
  transform: translateY(20vh);
  visibility: hidden;
  transition: opacity 0.6s ease-out, transform 1.2s ease-out;
  will-change: opacity, visibility;
}
.fade-in-section.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}

.project_info {
  color: white;
  padding: 8% 5% 0% 5%;
}

.ptitle {
  font-weight: bold;
}

.weather {
  width: 550px;
  height: auto;
}

@media (max-width: 415px) {
  .name {
    font-size: 50px;
  }

  .title {
    font-size: 12px;
  }

  .homeimage {
    animation: none;
    display: none;
  }

  .homebackground {
    height: 150px;
  }

  .hey {
    font-size: 50px;
  }

  p {
    font-size: 10px;
  }

  .headshot {
    padding-top: 50%;
  }

  .btn-info {
    width: 100%;
  }

  .projects_title {
    font-size: 80px;
  }

  .img-responsive {
    height: 180px;
    width: auto;
  }
}
